<template>
  <Banner bg-image="yellow-bg.jpg" talk-img="hope-box.png">
    <template v-slot:title>Make a hope box</template>
    <template v-slot:description>
      Learn how to make a hope box filled with items that can help you feel
      better or hopeful when you are down.
    </template>
  </Banner>
  <section class="gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold md:text-3xl text-blue">
        How to make a hope box
      </div>
      <p class="my-6">
        A hope box is personal to you. Find a box or other container and fill it
        with some of your favourite things; anything from your favourite stuffed
        animal to a puzzle to help keep you distracted.
      </p>
      <div class="mb-6 text-2xl font-bold text-center md:text-3xl text-blue">
        You can fill your hope box with anything—here are some ideas:
      </div>
      <!-- Questions  -->
      <div class="p-8 mt-8 bg-blue-200">
        <div
          class="flex items-center border-b-2 border-white-100 last:border-b-0"
          v-for="question in questions"
          :key="question.id"
        >
          <div class="m-6 text-4xl font-bold text-blue">
            {{ question.id }}
          </div>
          <div class="">
            {{ question.text }}
          </div>
        </div>
      </div>
      <!-- Questions end -->
    </div>
  </section>

  <Quote>
    <template v-slot:text>
      I use my hope box when I feel like giving-up
    </template>
    <template v-slot:sign> SB </template>
  </Quote>

  <FooterCard
    img="try-another-coping.png"
    text="View Tools & Resources"
    name="ToolsAndResources"
  >
    <template v-slot:header> Try another coping strategy </template>
    <template v-slot:content>
      Different coping strategies resonate with different people.<br />
      Discover more coping strategies to add to your toolbox.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import Quote from "@/components/Quote.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "MakeAHopeBox",
  components: {
    Banner,
    Quote,
    FooterCard,
  },
  data() {
    return {
      questions: [
        {
          id: 1,
          text: "Your favourite stuff animal",
        },
        {
          id: 2,
          text: "Something to distract you, like a puzzle or colouring book",
        },
        {
          id: 3,
          text: "Reminders of positive things you have learnt in therapy sessions",
        },
        {
          id: 4,
          text: "A copy of your safety plan",
        },
        {
          id: 5,
          text: "Photographs of you enjoying something you love, for example hiking, biking, a social gathering with friends",
        },
        {
          id: 6,
          text: "Your favorite sweets",
        },
        {
          id: 7,
          text: "A poem",
        },
        {
          id: 8,
          text: "Your favorite quotes",
        },
        {
          id: 9,
          text: "Photos of your family",
        },
      ],
    };
  },
};
</script>

<style scoped></style>
